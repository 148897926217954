@use '@angular/material' as mat;
@import 'node_modules/ngx-toastr/toastr';

.toast-container {
  .ngx-toastr {
    min-height: 48px;
    padding: 14px 16px;
    border-radius: 8px;
    &:hover {
      @include mat.elevation(10);
    }
  }

  .toast-success,
  .toast-info,
  .toast-warning,
  .toast-error {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 16px;
  }
  .toast-title{
    padding-right:5px;
  }
  .toast-success {
    background: #E0FDE3;
    color: #00BB40;
    font-weight: normal;
    font-size: 13px;
  }

  .toast-success:before{
    content: "\ea11";
    font-family: "icomoon" !important;
    font-size: 18px;
    color: #00BB40;
    vertical-align: text-bottom;
    padding-right: 3px;
  }

  .toast-info {
    background: #DCEFF5;
    color: #3D75A9;
    font-weight: normal;
    font-size: 13px;
  }

  .toast-info:before{
    content: "\e951";
    font-family: "icomoon" !important;
    font-size: 18px;
    color: #3D75A9;
    vertical-align: text-bottom;
    padding-right: 10px;
  }


  .toast-warning {
    background-color: #ff9800;
    font-weight: normal;
    font-size: 13px;
  }

  .toast-error {
    background: #FDECEC;
    color: #F03D3E;
    font-weight: normal;
    font-size: 13px;
  }
  
  .toast-error:before{
      content: "\ea07";
      font-family: "icomoon" !important;
      font-size: 18px;
      color: #E30613;
      vertical-align: text-bottom;
      padding-right: 10px;
  }

  .toast-close-button {
    text-shadow: 0 1px 0 rgba(0, 0, 0, .25);
    position: absolute;
    color:#666666;
    margin: 17px;
    &:hover {
      color: inherit;
      opacity: .6;
    }
  }
}
