//
// App Variables
//
// Layout
$gutter: 16px !default;

// Sidenav
$sidenav-width: 263px !default;
$sidenav-collapsed-width: 50px !default;
$sidenav-width-mobile: 280px !default;

// Toolbar
$toolbar-height-desktop: 64px !default;
$toolbar-height-mobile: 56px !default;

// Topmenu
$topmenu-sticky-position-desktop: $toolbar-height-desktop;
$topmenu-sticky-position-mobile: $toolbar-height-mobile;

// Typography
$font-size-base: 14px !default;
$font-weight-base: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

$font-size-h1: 40px !default;
$font-size-h2: 32px !default;
$font-size-h3: 28px !default;
$font-size-h4: 24px !default;
$font-size-h5: 20px !default;
$font-size-h6: 16px !default;

//
// Breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$breakpoints: (
  xsmall: 0,
  small: 600px,
  medium: 960px,
  large: 1280px,
  xlarge: 1920px
) !default;

//
// Scrollbar
//
$scrollbar-selectors: '.app-smart-table-content, .app-sidebar-inner, .scroll-box, .app-content-inner, .body-height, .join-height, .app-sidenav-inner, .selectd-categories';
