 @font-face {
    font-family: 'icomoon';
    src:  url('/assets/fonts/icomoon.eot?oktczv');
    src:  url('/assets/fonts/icomoon.eot?oktczv#iefix') format('embedded-opentype'),
      url('/assets/fonts/icomoon.ttf?oktczv') format('truetype'),
      url('/assets/fonts/icomoon.woff?oktczv') format('woff'),
      url('/assets/fonts/icomoon.svg?oktczv#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  [class^="iconmoon-"], [class*="iconmoon-"] {
      /* use !important to prevent issues with browser extensions that change fonts */
     font-family: 'icomoon' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
       line-height: 1;
   
     /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
   
    .iconmoon-person_outline:before {
      content: "\e900";
    }
    .iconmoon-home:before {
      content: "\e901";
    }
    .iconmoon-home2:before {
      content: "\e902";
    }
    .iconmoon-home3:before {
      content: "\e903";
    }
    .iconmoon-office:before {
      content: "\e904";
    }
    .iconmoon-pencil:before {
      content: "\e905";
    }
    .iconmoon-user:before {
      content: "\e971";
    }
    .iconmoon-search:before {
      content: "\e986";
    }
    .iconmoon-info:before {
      content: "\ea0c";
    }
    .iconmoon-arrow-right2:before {
      content: "\ea3c";
    }
    .iconmoon-arrow-left2:before {
      content: "\ea40";
    }
    .iconmoon-bell:before {
      content: "\e951";
    }
    .iconmoon-warning:before {
      content: "\ea07";
    }
    .iconmoon-notification:before {
      content: "\ea08";
    }
    .iconmoon-checkmark:before {
      content: "\ea10";
    }
    .iconmoon-checkmark2:before {
      content: "\ea11";
    }
  