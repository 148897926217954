/* You can add global styles to this file, and also import other style files */
@import "styles/app";

.mat-mdc-form-field-error::before {
  content: "\ea0c";
  font-family: "icomoon" !important;
  font-size: 12px;
  color: #E30613;
  vertical-align: text-bottom;
  padding-right: 3px;
}

.search-icon {
  color: white;
}

.ste-icons {
  color: white;
  padding-left: 10px;
}

.icon-filter::before {
  content: "\ea5b";
}

.popper-filter-container,
.popper-filter-container-audit {
  width: 480px;
  background: #f6f6f6 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  z-index: 99;
}

.popper-filter-container-audit {
  z-index: 120;
}

.popper-filter-container-market {
  width: 480px;
  background: #f6f6f6 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  z-index: 1000;
}

.popper-filter-container-supplier {
  width: 300px;
  background: #f6f6f6 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  z-index: 99;
}

.popper-filter-arrow::before {
  position: absolute;
  content: '';
  background: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
  border-right: none;
  width: 13px;
  height: 13px;
  transform: rotate(45deg);
  top: -3px;
}

.popper-filter-arrow {
  border-color: transparent transparent transparent transparent !important;
}

.float-right {
  float: right;
}

.word-break {
  word-break: break-all
}

.category-filter {
  .popper-filter-container-market {
    top: 45px !important;
  }

  .ngxp__arrow {
    display: none;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.configure-heading .form-field-text-area-style .mat-form-field-flex {
  height: 100px;
}

.qaleft {
  margin-left: 9%;
}

.remarks-table {
  figure {
    table {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;
    }

    table th,
    table td {
      border: 1px solid #dddddd;
      /* Border for cells */
      text-align: left;
      /* Text alignment */
      padding: 8px;
      /* Padding for cells */
    }

    table th {
      background-color: #f2f2f2;
      color: #333;
    }

    table tr:nth-child(even) {
      background-color: #f9f9f9;
      /* Zebra striping for even rows */
    }
  }
}

.ck-powered-by {
  display: none !important;
}