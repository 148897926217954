// ==================================================
@use '@angular/material' as mat;
// SIDENAV
// ==================================================

@mixin app-sidenav-theme($theme) {
  $background: mat.get-color-from-palette($theme, 'background');
  $foreground: mat.get-color-from-palette($theme, 'foreground');

  .app-sidenav {
    .app-sidemenu {
      a {
        color: mat.get-color-from-palette($foreground, text);
      }

      .app-sidemenu-item {
        &:hover {
          background: mat.get-color-from-palette($background, hover);
        }

        &.open {
          background: rgba(0, 0, 0, .02);
        }

        &.active {
          > a {
            color: mat.get-color-from-palette(map-get($theme, accent));
          }
        }
      }
    }

    .app-sidebar-header {
      color: mat.get-color-from-palette($foreground, text);
      background: #777777;
    }
  }
}

// User panel
.app-user-panel{
  width: 100%;
  background: #777777;
  border-radius: 0 0 0 50px;
}
.app-user-panel-avatar {
// Set default width and height can avoid flashing before avatar image loaded.
 width: 48px;
margin-top: 39px;
border-radius: 100%;
box-shadow: 0 0 0 20px rgba(196, 196, 196, 0.2), 
              0 0 0 40px rgba(196, 196, 196, 0.1);
outline:none;
}

.app-user-panel-name,
.app-user-panel-email,
.app-user-panel-icons {
color: white;
opacity: 1;
transition: swift-ease-out(opacity);
}

.app-user-panel-icons {
.mat-mdc-icon-button {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
}
.app-sidenav {
  position: absolute;
  overflow-x: hidden;
  width: $sidenav-width;
  background: #F7F7F7;
  transition: swift-ease-out(width); // Only set width property

 // @include mat-elevation(2);

  &.mat-drawer-side {
    border-width: 0;
  }

  .app-sidemenu {
    width: $sidenav-width;
    margin: 0;
    padding: 0;
    list-style: none;

    .app-sidemenu-item {
      display: block;
      height: auto;
      padding: 0;

      &.open {
        > .app-sidemenu {
          max-height: 2000px;
        }

        > .app-sidemenu-toggle > .menu-caret {
          transform: rotate(-180deg);
        }
      }
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      padding: 0 16px;
      text-decoration: none;
    }

    .menu-icon {
      width: 18px;
      height: 18px;
      margin-right: 16px;
      font-size: 18px;
      line-height: 18px;

      [dir='rtl'] & {
        margin-left: 16px;
        margin-right: auto;
      }
    }

    .menu-name,
    .menu-label,
    .menu-badge {
      transition: swift-ease-out(opacity);
    }

    .menu-label,
    .menu-badge {
      @include badge();
    }

    .menu-badge {
      border-radius: 10px;
    }

    .menu-caret {
      display: block;
      text-align: center;
      transition: fast-out-slow(transform);
    }

    &.level-0 > li > a > .menu-name {
      margin-right: 5px;

      [dir='rtl'] & {
        margin-left: 5px;
        margin-right: auto;
      }
    }

    &.level-1 > li > a {
      padding-left: 50px;

      [dir='rtl'] & {
        padding-left: 16px;
        padding-right: 50px;
      }
    }

    &.level-2 > li > a {
      padding-left: 64px;

      [dir='rtl'] & {
        padding-left: 16px;
        padding-right: 64px;
      }
    }

    &.submenu {
      max-height: 0;
      padding-top: 0;
      overflow: hidden;
      transform: translateZ(0) !important;
      transition: fast-out-slow(max-height);

      .app-sidemenu-item {
        border-left-width: 0;
      }
    }
  }

  .app-sidebar-header {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    height: $toolbar-height-desktop;
    padding: 0 10px;
    overflow: hidden;

    @include bp-lt(small) {
      & {
        height: auto;
      }
    }

    // Colorful
    .app-header-white & {
      background-color: #777777;
    }
  }

  .app-sidebar-inner {
    height: calc(100% - #{$toolbar-height-desktop});
    overflow: auto;

    @include bp-lt(small) {
      & {
        height: calc(100% - #{$toolbar-height-mobile});
      }
    }
  }
}

.app-sidenav-inner {
  height: 60vh;  
  overflow-y: auto;
}
// Layout control
.app-sidenav-collapsed,
.app-sidenav-collapsed-fix {
  .app-sidenav {
    width: $sidenav-collapsed-width;

    .menu-name,
    .menu-label,
    .menu-badge,
    .menu-caret,
    .app-user-panel-name,
    .app-user-panel-email,
    .app-user-panel-icons {
      opacity: 0;
    }

    .app-user-panel-avatar {
      transform: scale(.5);
    }

    &:hover {
      width: $sidenav-width;

      .menu-name,
      .menu-label,
      .menu-badge,
      .menu-caret,
      .app-user-panel-name,
      .app-user-panel-email,
      .app-user-panel-icons {
        opacity: 1;
      }

      .app-user-panel-avatar {
        transform: scale(1);
      }
    }
  }
}

.app-sidenav-collapsed {
  .app-content-wrap {
    margin-left: $sidenav-collapsed-width !important;

    [dir='rtl'] & {
      margin-left: auto !important;
      margin-right: $sidenav-collapsed-width !important;
    }
  }

  &[dir='rtl'] .app-content-wrap {
    margin-left: auto !important;
    margin-right: $sidenav-collapsed-width !important;
  }
}
.menu-container {
    .svg {
      padding: 0 10px;
    }
    .active {
      outline: none;
    color:white !important;
    background: #E30613 !important; 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
    .svg {
     
      filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(5);
    }
  }
  
  span .mat-mdc-card {
    outline: none;
    color:#22181C;
    width: 100px;
    height: 110px;
    background: transparent;
    box-shadow: none;
    margin-bottom:10px;
    background: linear-gradient(0deg, #F9F9F9, #F9F9F9);
    border: 1px solid #DBDBDB;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: center;
    padding: 14px 8px 0px 8px;
    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      
    }
    cursor: pointer;
    
  }
 
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
  }
  
}

.popper-container {
  width: 200px;
  background: #FFFFFF !important;
  border: 1px solid #DDD7D7 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  z-index: 99;
}
popper-content {
  .ngxp__container {
      display: block!important;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;

      &[aria-hidden="true"] {
          visibility: hidden;
      }
  }
}
.popper-arrow {
  border-color: transparent transparent transparent transparent !important;
  //top: calc(20% - 5px) !important;
}
.popper-arrow::before{
  position: absolute;
    content: '';
    background: #fff;
    border: 1px solid #bbc0c4;
    border-bottom: none;
    border-right: none;
    width: 13px;
    height: 13px;
    transform: rotate(-45deg);
    left: -2px;
}
.popper-container .mat-mdc-list-item {
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  text-align: left;
  outline : none;
}

// .ngxp__container[x-placement^=right] {
//   margin-left: 8px !important;
// }

.app-user-panel-icons .mat-mdc-icon-button {
  .mat-mdc-button-persistent-ripple {
    display: none;
  }
  .mat-ripple {
    display: none;
  }
  .mat-badge-content {
    font-weight: 600;
    font-size: 12px;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
}
}