// ==================================================
@use '@angular/material' as mat;
// TOPNAV
// ==================================================

@mixin app-topnav-theme($theme) {
  $background: mat.get-color-from-palette($theme, 'background');
  $foreground: mat.get-color-from-palette($theme, 'foreground');

  .app-topmenu {
    background: mat.get-color-from-palette($background, app-bar);
    color: mat.get-color-from-palette($foreground, text);
  }
}

.app-topmenu {
  position: sticky;
  z-index: 200;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @include mat.elevation(2);

  .menu-label,
  .menu-badge {
    margin-left: 8px;
    font-size: 12px;

    [dir='rtl'] & {
      margin-left: auto;
      margin-right: 8px;
    }
  }

  .menu-badge {
    border-radius: 10px;
  }

  .menu-caret {
    margin-right: -8px;

    [dir='rtl'] & {
      margin-left: -8px;
      margin-right: auto;
    }
  }
}

.app-topmenu-inner {
  padding: 8px;
  white-space: nowrap;
}

// Layout control
.app-navbar-top {
  .app-topmenu {
    top: 0;
  }

  .app-branding {
    margin-left: 16px;

    [dir='rtl'] & {
      margin-left: auto;
      margin-right: 16px;
    }
  }
}

// Layout control
.app-header-fixed {
  .app-topmenu {
    top: $topmenu-sticky-position-desktop;

    @include bp-lt(small) {
      & {
        top: $topmenu-sticky-position-mobile;
      }
    }
  }
}
