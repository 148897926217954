// ==================================================
// IMAGE
// ==================================================

.fit-cover {
  @include image-fit(cover);
}

.fit-contain {
  @include image-fit(contain);
}
