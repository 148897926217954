// ==================================================
// Material Components Style Fix
// ==================================================

.mat-mdc-table {
  width: 100%;
}

.mat-mdc-card {
  margin-bottom: $gutter;
}

.mat-accordion {
  display: block;
  margin-bottom: $gutter;
}

.mat-mdc-list-base {
  position: relative;
}

// Form horizontal
.app-form-horizontal {
  .mat-mdc-form-field {
    width: 100%;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #555555;
  transition: background-color 5000s ease-in-out 0s;
}
.common-drop-down-style {
  margin-top: 30px;
}

.login {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-prefix {
    top: 0px !important;
    margin-left: 10px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-outline {
    color: #c4c4c4 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding: 15px 1em;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1em 0 !important;
  }
}

.password-page {
  .mat-mdc-input-element {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 1em !important;
    color: #22181c !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-outline {
    color: #c4c4c4 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #616161 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    padding-top: 0.5em !important;
    margin-bottom: 7px !important;
  }
  //   .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  //     padding: 14px 1em;
  // }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 1.34375em;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    height: 54px;
  }
}

.reject-text-field {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1em !important;
  color: #22181c !important;
  border-radius: 5px;
  margin-top: 8px;
  height: 80px;
}

.register {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    height: 40px;
  }
  .form-field-text-area-style {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      height: 60px; //change height 50px to 60px
    }
  }
  .upload-file-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      padding-right: 2px;
      height: 40px;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-prefix {
    top: 0px !important;
    margin-left: 10px;
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 1em !important;
    color: #22181c !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-outline {
    color: #c4c4c4 !important;
  }
  body {
    background-color: white !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 599.99px) {
  .register {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      //width: 327px;
      height: 40px;
    }
  }
  .add-task-container,
  .edit-tender-container {
    width: 320px !important;
  }
  .view-popup-container {
    .mat-mdc-dialog-container {
      width: 320px !important;
    }
  }
}

.send-message,
.message-centre-supplier,
.add-information {
  .form-field-text-area-message {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      height: 150px;
    }
  }
}

.add-product,
.responded-tender,
.buyer-tender,
.add-service,
.rfq-respond,
.create-tender,
.add-user,
.edit-supplier,
.price-conversion,
.send-message,
.message-centre-supplier,
.add-information,
.audit {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    //width: 330px;
    height: 40px;
  }
  .form-field-text-area-style {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      height: 60px;
    }
  }
  .upload-file-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      padding-right: 2px;
      height: 40px;
    }
  }
}

.register,
.supplier-details,
.audit,
.responded-tender,
.buyer-tender,
.add-product,
.add-service,
.add-information,
.rfq-respond,
.create-tender,
.add-user,
.edit-supplier,
.price-conversion,
.send-message,
.message-centre-supplier {
  .mat-mdc-input-element {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 1em !important;
    color: #706f6f !important;
    padding: 4px;
    // margin-bottom: 15px  !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .isdcode.mat-form-field-appearance-outline .mat-form-field-prefix {
    top: -0.25rem !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-prefix {
    // bottom: 0.8rem !important;
    // top: -0.8rem !important;
    top: -0.1rem !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    // bottom: 0.8rem !important;
    // top: -0.8rem !important;
    top: -0.1rem !important;
  }
  mat-icon.picker-icon.cursor-pointer.mat-icon.notranslate.material-icons.mat-icon-no-color {
    margin-top: -6px !important;
  }
  input.mat-mdc-input-element {
    margin-top: -1.0625em !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-outline {
    color: #c4c4c4 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #616161 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 1em;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    padding: 0px !important;
    border-top: 0.8em solid transparent !important;
  }
  .mat-mdc-select {
    margin-top: 1px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 1em !important;
    color: #706f6f !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-arrow-wrapper {
    transform: none !important;
    vertical-align: none !important;
  }
}

.price-conversion {
  .mat-mdc-input-element {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 1em !important;
    color: #22181c !important;
  }
}

.search-form {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    height: 40px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding: 0px !important;
    margin: 0px !important;
    margin-bottom: 4px !important;
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-label {
  margin-top: -15px;
}
label.ng-star-inserted {
  transform: translateY(-0.59375em) scale(0.75) !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
  padding-bottom: 10px;
  padding-top: 10px;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  display: inline-block !important;
}

//.mat-form-field-flex > .mat-form-field-infix { padding: 5px 0px !important;}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-type-mat-input .mat-form-field-flex > .mat-form-field-infix {
  padding: 5px 0px !important;
}
//text area padding issue:
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.form-field-text-area-style .mat-form-field-flex > .mat-form-field-infix {
  padding: 0px 0px !important;
}
//search text field align issue:
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.search-field .mat-form-field-flex > .mat-form-field-infix {
  padding: 0px 0px !important;
}
//text area message height issue:
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.form-field-text-area-message .mat-form-field-flex > .mat-form-field-infix {
  height: 140px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-label {
  margin-top: -15px;
}
label.ng-star-inserted {
  transform: translateY(-0.59375em) scale(0.75) !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
  padding-bottom: 0px;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
  margin: 0px;
}

.price-popup-container {
  .mat-mdc-dialog-container {
    font-size: 14px;
    padding: 0px;
    height: auto;
    width: 600px;
  }
  .mat-mdc-dialog-title {
    background: #777777;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    padding: 16px;
  }
  .mat-mdc-dialog-content {
    margin: 0px;
  }
  .mat-mdc-dialog-actions {
    padding: 16px !important;
    margin: 0px !important;
  }
}

.approve-popup-container {
  .mat-mdc-dialog-container {
    font-size: 14px;
    padding: 0px;
    width: 1200px;
  }
  .mat-mdc-dialog-title {
    background: #777777;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    padding: 16px;
  }
  .mat-mdc-dialog-content {
    margin: 0px;
    height: 440px;
    overflow: hidden;
  }
  .mat-mdc-dialog-actions {
    padding: 16px !important;
    margin: 0px !important;
  }
}

.view-category-popup {
  .mat-mdc-dialog-container {
    font-size: 14px;
    padding: 0px;
    width: 800px;
  }
  .mat-mdc-dialog-title {
    background: #777777;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    padding: 16px;
  }
  .mat-mdc-dialog-content {
    margin: 0px;
    height: 400px;
    overflow: hidden;
  }
  .mat-mdc-dialog-content.list-categories {
    height: 350px;
  }
  .mat-mdc-dialog-actions {
    padding: 16px !important;
    margin: 0px !important;
  }
}

.view-popup-container,
.responded-popup-container,
.send-message-popup-container {
  .mat-mdc-dialog-container {
    font-size: 14px;
    padding: 0px;
    height: auto;
    width: 1000px;
  }
  .mat-mdc-dialog-title {
    background: #777777;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    padding: 16px;
  }
  .mat-mdc-dialog-content {
    margin: 0px;
  }
  .mat-mdc-dialog-actions {
    padding: 16px !important;
    margin: 0px !important;
  }
}

.add-task-container {
  width: 600px;
  .mat-mdc-dialog-container {
    padding: 0px !important;
    width: 100%;
    height: 100%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    height: 36px;
  }
  .form-field-text-area-style {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      height: 90px;
    }
  }
  .mat-mdc-form-field {
    width: 100%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .add-task-container .mat-form-field-flex {
    height: 42px !important;
  }
}

.edit-tender-container {
  width: 700px;
  .mat-mdc-dialog-container {
    padding: 0px !important;
    width: 100%;
    height: 100%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    height: 36px;
  }
  .form-field-text-area-style {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      height: 90px;
    }
  }
  .mat-mdc-form-field {
    width: 100%;
  }
}

.add-task-container,
.view-popup-container,
.edit-tender-container {
  .panelHeader {
    height: 50px;
    background: #777777;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    padding: 16px 14px 16px 14px;
    margin-top: 0px !important;
  }
  .mat-mdc-input-element {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 1em !important;
    color: #706f6f !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-outline {
    color: #c4c4c4 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #616161 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 1em;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    padding: 0px !important;
    border-top: 0.8em solid transparent !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.3em !important;
    right: 1em !important;
  }
  .mat-icon {
    cursor: default;
  }
}
#otp-expired-page {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    width: 420px;
    height: 58px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    padding: 0px !important;
    border-top: 1.3em solid transparent !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-outline {
    color: #c4c4c4 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #616161 !important;
  }
}

.action-button {
  width: fit-content;
  height: 36px;
  background-color: #e30613 !important;
  border: 1px solid #555555 !important;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff !important;
  cursor: pointer;
}
.cancel-button {
  width: fit-content;
  height: 36px;
  background: #ffffff;
  border: 1px solid #555555 !important;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #555555 !important;
}

.mat-mdc-outlined-button {
  width: fit-content;
}
.home {
  // .mat-tab-label{
  //   background: #F9F9F9;
  //   width: 286px;
  //   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  //   border-radius: 4px;
  //   max-width: 286px;
  //   max-height: 48px;
  //   font-family: Montserrat;
  //   font-style: normal;
  //   font-weight: bold;
  //   font-size: 16px;
  //   line-height: 22px;
  //   color:#22181C;
  //   opacity: 1 !important;
  // }

  // .mat-tab-label-active{
  //   opacity: 1 !important;
  //   background: #E30613;
  //   width: 286px;
  //   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  //   border-radius: 4px;
  //   max-width: 286px;
  //   max-height: 48px;
  //   color: white;
  //   font-family: Montserrat;
  //   font-style: normal;
  //   font-weight: bold;
  //   font-size: 16px;
  //   line-height: 22px;
  // }

  .mdc-tab {
    background: #f9f9f9;
    width: 286px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    max-width: 286px;
    max-height: 48px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    opacity: 1 !important;

    .mdc-tab__content {
      color: #22181c;
    }
  }

  .mdc-tab.mdc-tab--active {
    opacity: 1 !important;
    background: #e30613;
    width: 286px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    max-width: 286px;
    max-height: 48px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;

    .mdc-tab__text-label {
      color: white;
    }
  }

  // .mat-tab-header{
  //   max-width: 575px;
  // }
  .mat-mdc-tab-header {
    max-width: 575px;
  }
}

.approved-supplier-view-tab-style,
.view-tender-tabs {
  .mat-mdc-tab-group {
    box-shadow: none;
  }
  .mat-mdc-tab-header {
    border-bottom: none;
  }
  .mdc-tab.mdc-tab--active {
    background: #e7e7e7;
    border-radius: 100px;
  }
  .mdc-tab .mdc-tab-indicator,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: white;
    height: 0px;
  }
  .mdc-tab {
    height: 28px;
    .mdc-tab__content {
      font-weight: 600;
    }
  }
}

.responded-tender,
.buyer-tender {
  .mdc-tab {
    min-width: 95px;
    .mdc-tab__content .mdc-tab__text-label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      color: #22181c !important;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 0;
  }
  .mdc-tab .mdc-tab-indicator .mdc-tab-indicator__content--underline,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    height: 4px;
    background-color: #e30613;
  }
}

.rfq-respond {
  .mat-mdc-card {
    box-shadow: none;
    background: #ffffff;
    padding: 0px !important;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    font-family: Montserrat;
    color: #22181c !important;
  }
  .mdc-tab {
    .mdc-tab__content .mdc-tab__text-label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 0;
  }
  // .mat-mdc-tab-header {
  //   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  // }
}

.edit-supplier,
.add-product,
.create-tender {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-prefix {
    top: 0em !important;
  }
}

.mat-column-partNo,
.mat-column-productName,
.mat-column-productDescription {
  width: 22%;
  padding-right: 5px !important;
  word-break: break-all;
}

.mat-column-uom,
.mat-column-quantity {
  width: 10%;
  padding-right: 5px;
  word-break: break-all;
}

.mat-column-action {
  width: 14%;
}

.tender-product-service-container {
  table {
    td.mat-mdc-cell,
    td.mat-mdc-footer-cell,
    th.mat-mdc-header-cell {
      padding-right: 15px !important;
    }
  }
}
.info {
  .popper-container {
    width: 300px;
    z-index: 999;
  }
}

// Dialog override - mdc
// .mat-mdc-dialog-title {
//     background: #777777;
//     font-weight: bold;
//     font-size: 16px;
//     line-height: 22px;
//     color: #FFFFFF;
//     padding : 16px;
//     font-family: Montserrat, "Open Sans";
// }

// .mat-mdc-dialog-content {
//     font-family: Montserrat, "Open Sans" !important;
// }
