// -----------------
@import 'functions';
@import 'variables';
@import 'mixins';
// -----------------
@import 'display';
@import 'position';
@import 'size';
@import 'spacing';
@import 'border';
@import 'radius';
@import 'fonts';
@import 'text';
@import 'alignment';
@import 'colors';
@import 'cursor';
@import 'image';
@import 'icons';

// Generate Helpers
@each $key, $utility in $utilities {
  $values: map-get($utility, values);

  @each $class-modifier, $value in $values {
    $properties: map-get($utility, property);
    $property-class-prefix: map-get($utility, class);
    $property-class-prefix-hyphen: if(
      $property-class-prefix== '',
      $property-class-prefix,
      $property-class-prefix + '-'
    );

    @if type-of($properties) == 'string' {
      $properties: append((), $properties);
    }

    .#{$property-class-prefix-hyphen + $class-modifier} {
      @each $property in $properties {
        #{$property}: $value !important;
      }
    }
  }
}
