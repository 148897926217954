// ==================================================
// LAYOUT
// ==================================================
@use 'sass:math';


.app-container-wrap,
.app-container {
  height: auto;
  z-index: 0;
  background-image: linear-gradient(to bottom, #777777, #777777 250px, #FFF 250px, #FFF);
	background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (min-width: 320px) and (max-width: 599.99px){
  .app-content {
    margin-right: 0px !important; 
    padding-right: 0px !important;
  }
}
.app-content {
  margin-right: 0px;
  position: relative;
  z-index: 105;
  width:100%;
  height: calc(100vh - 64px);
  border-radius: 20px 20px 0px 0px;
  padding-right:16px;
}
.app-content-inner {
  position: relative;
  z-index: 300;
  height: calc(100vh - 64px);
  max-width: 100%;
  max-height: 92vh;
  overflow: auto;
}
// TODO: Grid row
.app-row {
  margin-right: math.div(-$gutter, 2);
  margin-left: math.div(-$gutter, 2);
}

// TODO: Grid col
.app-col {
  padding-right: math.div($gutter, 2);
  padding-left: math.div($gutter, 2);
}

// Layout control
.app-header-above {
  .app-container {
    height: calc(100% - 260px) !important;
  }

  .app-sidebar-inner {
    height: 100% !important;
  }
}

// Fix the init content width
.app-content-width-fix {
  .app-content-wrap {
    margin-left: $sidenav-width !important;

    [dir='rtl'] & {
      margin-left: auto !important;
      margin-right: $sidenav-width !important;
    }
  }
}

//
// Auth
//
.app-auth-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

