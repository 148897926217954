// ==================================================
// BRANDING
// ==================================================

.app-branding {
  color: inherit;
  white-space: nowrap;
  font-size: 16px;

  .app-branding-logo-expanded {

    // margin: 0px 12px 0px 12px;
    margin: 0px 12px 0px 4px;
    vertical-align: middle;
    width: 13vw;

    [dir='rtl'] & {
      margin-left: 10px;
      margin-right: auto;
    }
  }

  .app-branding-name {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    vertical-align: middle;
  }
}
