@import '~@ng-select/ng-select/themes/material.theme.css';

.ng-dropdown-panel {
  border-radius: 4px;

  &.ng-select-bottom,
  &.ng-select-top {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14),
      0 1px 10px 0 rgba(0, 0, 0, .12);
  }
}


.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  position : relative;
}

.ng-select .ng-select-container{
//  min-height: 45px;
  margin-top: -4px;
}
.ng-select .ng-select-container .ng-value-container {
  padding: .4375em 0 !important;
  border-top: .74375em solid transparent !important;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  left: 10px !important;
}
.ng-select .ng-select-container.ng-appearance-outline {
  min-height: 43px;
}
.ng-select {
  line-height: 1.2;
  padding-bottom: 0;
}
.ng-select.ng-select-single .ng-select-container.ng-appearance-outline {
  .ng-arrow-wrapper {
    bottom: 9px;
  }
  .ng-clear-wrapper {
    bottom: 11px;
  }
} 
.ng-select.ng-select-single .ng-select-container.ng-appearance-outline.ng-has-value {
  .ng-arrow-wrapper {
    bottom: 11px;
  }
  .ng-clear-wrapper {
    bottom: 9px;
  }
} 