// ==================================================
// HEADER
// ==================================================

.app-header {
  position: relative;
  z-index: 0;
  display: block;
  background: url('/assets/images/header-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
 // background: #777777;
  .app-toolbar {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  .app-user-avatar {
    margin-right: 8px;

    [dir='rtl'] & {
      margin-left: 8px;
      margin-right: auto;
    }
  }

  // Layout control
  .app-header-fixed & {
    position: sticky;
    top: 0;
  }
//   // User panel
//   .app-user-panel{
//     width: 260px;
//   }
// .app-user-panel-avatar {
//   // Set default width and height can avoid flashing before avatar image loaded.
//    width: 72px;
//   margin-top: 15px;
//   border-radius: 100%;
//   box-shadow: 0 0 0 25px rgba(196, 196, 196, 0.2), 
//                 0 0 0 50px rgba(196, 196, 196, 0.1);
// }

// .app-user-panel-name,
// .app-user-panel-email,
// .app-user-panel-icons {
//   color: white;
//   opacity: 1;
//   transition: swift-ease-out(opacity);
// }

// .app-user-panel-icons {
//   .mat-icon-button {
//     width: 40px;
//     height: 40px;
//     line-height: 40px;
//   }
//   }
}

.app-toolbar {
  .badge {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 12px;
    border-radius: 10px;
  }
}

// Colorful
.app-header-white {
  .app-toolbar,
  .app-topmenu {
    background-color: transparent;
  }
}

.app-hamburger.mat-mdc-button {
  color: #fff;
  height: 100%;
  padding: 0;

  @include bp-lt(small) {
    & {
      min-width: 15%;
    }
  }
}
