@use '@angular/material' as mat;
@import './icon';
@import '~ng-pick-datetime/assets/style/picker.min.css';
//@import '@angular/material/theming';
@include mat.checkbox-density(-1);
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

//
//
//
$mat-stengg-gray: (
  50: #efefef,
  100: #d6d6d6,
  200: #bbbbbb,
  300: #a0a0a0,
  400: #8b8b8b,
  500: #777777,
  600: #6f6f6f,
  700: #616161,
  800: #5a5a5a,
  900: #474747,
  A100: #f6b2b2,
  A200: #f18585,
  A400: #ff4444,
  A700: #ff2a2a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$mat-stengg-red: (
  50: #fce1e3,
  100: #f7b4b8,
  200: #f18389,
  300: #eb515a,
  400: #e72b36,
  500: #e30613,
  600: #e00511,
  700: #dc040e,
  800: #d8030b,
  900: #d00206,
  A100: #fff8f8,
  A200: #ffc5c6,
  A400: #ff9293,
  A700: #ff7979,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
//
// Light style
//
// $primary: mat-palette($mat-stengg, 500);
// $accent: mat-palette($mat-stengg, A200, A100, A400);
// $theme: mat-light-theme($mat-stengg, $accent);

$primary: mat.define-palette($mat-stengg-red, 500);
$accent: mat.define-palette($mat-stengg-gray, 700);
$warn: mat.define-palette(mat.$red-palette, 500);

// $theme: mat.define-light-theme(
//   $primary,
//   $accent,
//   $warn
// );
$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
  )
);

//////////////////////////////////////////////////////////////////////////////////////
// When we use the Schematics (`ng add`) to start, the following variables will be  //
// used to create a dark theme! This strategy will not generate redundant codes.    //
//////////////////////////////////////////////////////////////////////////////////////

//
// Dark style
//
// $primary: mat-palette($mat-blue);
// $accent: mat-palette($mat-amber, A200, A100, A400);
// $theme: mat-dark-theme($primary, $accent);

// @import url('https://fonts.google.com/specimen/Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

$config: mat.define-typography-config(
  $font-family: 'Montserrat',
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($theme);

//
// App theme
//
@import 'theming';

@include app-app-theme($theme);

//
// Demo purpose only
//
.theme-dark {
  $primary: mat.define-palette(mat.$blue-palette);
  $accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);

  $theme: mat.define-dark-theme(
    (
      color: (
        primary: $primary,
        accent: $accent,
      ),
    )
  );

  //@include mat.all-legacy-component-themes($theme);
  @include app-app-theme($theme);
}

.cursor-pointer {
  cursor: pointer;
}

.text-view-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #22181c;
}

.ste-red {
  color: #e30613;
}

.width-hundread-percent {
  width: 100%;
}

.attach-icon-style {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: #555555;
  width: 10px !important;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
