// ==================================================
// BASE
// ==================================================

html,
body {
  height: 100%;
  min-height: 100%;
  line-height: normal;
  font-family: Montserrat, 'Open Sans';
  font-style: regular;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  background: #F7F7F7;
  overflow: auto;
}

body {
  margin: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;

  // &:hover {
  //   color: #0056b3;
  //   text-decoration: underline;
  // }
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

code,
kbd,
pre,
samp {
  font-family: 'Roboto Mono', monospace;
}

code {
  font-size: 87.5%;
  color: red;
  word-break: break-word;
}

.clearfix {
  @include clearfix();
}
