.view-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    color: #E30613
}
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: auto;
}
.double-bounce1, .double-bounce2 {
    background-color: #E30613;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}
.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
    background-color: #555555;
}
.view-loader {
    display: block;
    padding-top: 23%;
    background: rgba(255, 255, 255, .3);
    z-index: 9999;
}
.view-loader * {
    margin: auto;
}
@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bounce {
    0%, 100% { 
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 
    50% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}